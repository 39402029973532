import React from 'react';
import { Link } from 'gatsby';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import './thanks.less';

const thanksPage = ({}) => (
  <Layout>
    <SEO title="Sign Up" keywords={[`gatsby`, `application`, `react`, `training`]} />

    <Grid centered columns={12}>
      <Grid.Row>
        <Grid.Column computer={5} tablet={7} mobile={12}>
          <Header as="h1">Thank You!</Header>
          <p>Thanks for signing up for our mailing list.</p>
          <p>We'll be in touch soon, as new tutorials are published.</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Button icon labelPosition="left" size="huge" secondary>
          <Icon name="right arrow" />
          <Link to="/#tutorial-list">
            Back to the tutorials
          </Link>
        </Button>
      </Grid.Row>
    </Grid>
  </Layout>
);

export default thanksPage;
